export const contentfulQueryKeys = {
    pageHeaderQuery: ['pageHeaderQuery'],
    homeEventSection: ['homeEventSection'],
    homeProductCarousel: ['homeProductCarousel'],
    pdpBlogsCarousel: ['pdpBlogsCarousel'],
    pdpRecommendedProductsCarousel: ['pdpRecommendedProductsCarousel'],
    homeBookingSection: ['homeBookingSection'],
    homeArticleSection: ['homeArticleSection'],
    homePageBottomSection: ['homePageBottomSection'],
    getFooterQuery: ['getFooterQuery'],
    getProductCarousel: ['getProductCarousel'],
    getHomeVideoSectionQuery: ['getHomeVideoSectionQuery'],
    getRichTextContentQuery: ['getRichTextContentQuery'],
    ingredients: ['ingredients'],
    brands: ['brands'],
    messages: ['messages'],
    getBlogsSection: ['getBlogsSection'],
    getHomeHeroSection: ['getHomeHeroSection'],
    getNewsletterQuery: ['getNewsletterQuery'],
    getPlpStaticFiltersQuery: ['getPlpStaticFiltersQuery'],
    getUserProfileTextAsset: ['profilePageUserDetails'],
    staticTemplatePage: ['staticTemplatePage'],
    staticPagesLeftMenu: ['staticPagesLeftMenu'],
    homePageCategorySection: ['homePageCategorySection'],
    getCookieModalQuery: ['getCookieModalQuery'],
    homeContentGridOne: ['homeContentGridOne'],
    homeContentGridTwo: ['homeContentGridTwo'],
    getPageQuery: ['getPageQuery'],
    getRealmBlogPage: ['getRealmBlogPage'],
    getRealmBlogPageQuery: ['getRealmBlogPageQuery'],
    getBlogArticle: ['getBlogArticle'],
    getMenuQuery: ['getMenuQuery'],
    getDashboardPageQuery: ['getDashboardPageQuery'],
    myPageBodySection: ['myPageBodySection']
}
